<main-header></main-header>

<section class="hero hero-overlay d-flex flex-column justify-content-center py-5"
  style="background-image: url({{hero}});">
  <div class="container position-relative py-5">
    <div class="col-lg-7 d-flex flex-column justify-content-center">
      <h1 class="hero-title mb-3 text-light display-6 fw-medium"
        [innerHTML]="(pageData[0]) ? (pageData[0][0]) ? pageData[0][0].value : '' : ''">¿Quieres ser un asesor Solviing?
      </h1>
      <p class="hero-text mb-0 fs-5 text-light"
        [innerHTML]="(pageData[0]) ? (pageData[0][1]) ? pageData[0][1].value : '' : ''">Como asesor Solviing cuentas con
        el apoyo de nuestra plataforma para atender a tus clientes en sus necesidades de compra y venta de vivienda.</p>
    </div>
  </div>
</section>

<section class="py-5">
  <div class="container">
    <div class="w-lg-100 mx-auto d-flex flex-column justify-content-center">
      <p class="fs-5 mb-4 opacity-50 text-uppercase"
        [innerHTML]="(pageData[1]) ? (pageData[1][0]) ? pageData[1][0].value : '' : ''">Paso a paso</p>
      <!-- <h3 class="fs-1 mb-4 section-title">¿Cómo vender tu propiedad?</h3> -->
      <div class="d-flex flex-column gap-5">
        <div class="d-flex gap-3">
          <div class="align-items-center bg-primary d-flex flex-column fs-4 justify-content-center rounded-1 text-light"
            style="width: 40px; height: 40px;">1</div>
          <div class="d-flex flex-column w-100">
            <div class="fs-4 fw-medium" style="line-height: 40px;"
              [innerHTML]="(pageData[1]) ? (pageData[1][1]) ? pageData[1][1].value : '' : ''">Registro</div>
            <p class="fs-5 fw-light m-0"
              [innerHTML]="(pageData[1]) ? (pageData[1][2]) ? pageData[1][2].value : '' : ''">Regístrate como Asesor
              inmobiliario de Solviing para que puedas traer propiedades con nosotros.</p>
          </div>
        </div>
        <div class="d-flex gap-3">
          <div class="align-items-center bg-primary d-flex flex-column fs-4 justify-content-center rounded-1 text-light"
            style="width: 40px; height: 40px;">2</div>
          <div class="d-flex flex-column w-100">
            <div class="fs-4 fw-medium" style="line-height: 40px;"
              [innerHTML]="(pageData[1]) ? (pageData[1][3]) ? pageData[1][3].value : '' : ''">Apoyo Solviing</div>
            <p class="fs-5 fw-light m-0"
              [innerHTML]="(pageData[1]) ? (pageData[1][4]) ? pageData[1][4].value : '' : ''">Si se te complica vender
              los inmuebles de tus clientes por las condiciones en las que se encuentran, Solviing le presta a tu
              cliente para rehabilitar la propiedad y pagar sus adeudos de servicios y Solviing te comparte comisión en
              la venta.</p>
          </div>
        </div>
        <div class="d-flex gap-3">
          <div class="align-items-center bg-primary d-flex flex-column fs-4 justify-content-center rounded-1 text-light"
            style="width: 40px; height: 40px;">3</div>
          <div class="d-flex flex-column w-100">
            <div class="fs-4 fw-medium" style="line-height: 40px;"
              [innerHTML]="(pageData[1]) ? (pageData[1][5]) ? pageData[1][5].value : '' : ''">Beneficio Para Tus
              Cliente: Oferta de Compra</div>
            <p class="fs-5 fw-light m-0"
              [innerHTML]="(pageData[1]) ? (pageData[1][6]) ? pageData[1][6].value : '' : ''">Compramos el inmueble de
              tu cliente de forma rápida, otorgándole liquidez inmediata y si cuenta con un crédito nosotros nos
              encargamos de liquidar su adeudo y pagar los saneamientos que tenga pendientes de pago.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="py-5">
  <div class="container">
    <p class="m-0 text-muted text-uppercase fw-bold text-center" style="letter-spacing: 3px;"
      [innerHTML]="(pageData[2]) ? (pageData[2][1]) ? pageData[2][1].value : '' : ''">Resolvemos tus dadas</p>
    <h3 class="fs-1 mb-4 section-title text-center"
      [innerHTML]="(pageData[2]) ? (pageData[2][0]) ? pageData[2][0].value : '' : ''">Preguntas frecuentes</h3>

    <div class="w-lg-100 mx-auto">
      <div ngbAccordion [closeOthers]="true">
        <div ngbAccordionItem [collapsed]="false" class="border-0 mb-2">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton class="bg-light shadow-none rounded-3"
              [innerHTML]="(pageData[2]) ? (pageData[2][2]) ? pageData[2][2].value : '' : ''">
              ¿Qué significa ser un asesor Solviing?
            </button>
          </h2>
          <div ngbAccordionCollapse class="">
            <div ngbAccordionBody>
              <ng-template>
                <div [innerHTML]="(pageData[2]) ? (pageData[2][3]) ? pageData[2][3].value : '' : ''">Ser un Broker
                  Solviing significa ser un asesor inmobiliario certificado, ser experto en la comercialización de
                  bienes inmuebles y tener una cartera de propiedades para ofrecer a tus clientes.</div>
              </ng-template>
            </div>
          </div>
        </div>
        <div ngbAccordionItem class="border-0 mb-2">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton class="bg-light shadow-none rounded-3"
              [innerHTML]="(pageData[2]) ? (pageData[2][4]) ? pageData[2][4].value : '' : ''">
              ¿Quién puede ser un asesor Solviing?
            </button>
          </h2>
          <div ngbAccordionCollapse class="">
            <div ngbAccordionBody>
              <ng-template>
                <div [innerHTML]="(pageData[2]) ? (pageData[2][5]) ? pageData[2][5].value : '' : ''">Profesionales
                  inmobiliarios afiliados o independientes que cuenten con los conocimientos necesarios en los procesos
                  y asesoría inmobiliaria.</div>
              </ng-template>
            </div>
          </div>
        </div>
        <div ngbAccordionItem class="border-0 mb-2">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton class="bg-light shadow-none rounded-3"
              [innerHTML]="(pageData[2]) ? (pageData[2][6]) ? pageData[2][6].value : '' : ''">
              Beneficios
            </button>
          </h2>
          <div ngbAccordionCollapse class="">
            <div ngbAccordionBody>
              <ng-template>
                <div [innerHTML]="(pageData[2]) ? (pageData[2][7]) ? pageData[2][7].value : '' : ''">
                  <p>Ser un broker Solviing te dará beneficios y herramientas dentro de nuestra plataforma, que te
                    permitirán cerrar transacciones de manera más rápida además de que te llevaremos de la mano en tus
                    cierres inmobiliarios.</p>

                  <ul>
                    <li>Te ayudamos con todos los trámites. Nosotros te ayudaremos con todo el proceso en la gestión de
                      venta y proceso notarial, para que puedas cerrar la venta en el menor tiempo posible.</li>
                    <li>Podrás ganar comisiones por referirnos a tus clientes y por promover nuestras viviendas. Te
                      daremos acceso a nuestro portafolio de viviendas para promoverlas con tus clientes y ganar
                      comisiones de forma rápida.</li>
                    <li>Asesoría continua para tus cierres de ventas. Nuestro equipo de comercialización te ayudará
                      durante todo el proceso de compra o venta, y te brindará asesoría en todo momento.</li>
                    <li>Beneficios para tu cliente. Le damos una oferta de compra. Compramos el inmueble de tu cliente
                      de
                      forma rápida, otorgándole liquidez inmediata y si cuenta con un crédito nosotros nos encargamos de
                      liquidar su adeudo y pagar los saneamientos que tenga pendientes de pago.</li>
                  </ul>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section>
  <div class="container">
    <need-help></need-help>
  </div>
</section>

<main-footer></main-footer>